
import { defineComponent, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getStreamsDetail } from "./streams";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import moment from "moment";

export default defineComponent({
  name: "detail-berita",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const berita = ref(0);

    const id = ref(route.params.id);

    // const res = getStreamsDetail(id.value)
    const fetchStreamDetail = async () => {
      const res = await getStreamsDetail(id.value);
      berita.value = res;
      if (
        berita.value["source"] != "youtube" &&
        berita.value["source"] != "instagram" &&
        berita.value["source"] != "twitter" &&
        berita.value["source"] != "tiktok"
      ) {
        berita.value["rate_bw"] = berita.value["rate_bw"]
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        berita.value["rate_fc"] = berita.value["rate_fc"]
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    };

    const formatDate = (date) => {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    };

    const goToRoute = (id: string, name: string) => {
      router.push({ name: name, params: { id: id } });
    };

    fetchStreamDetail();

    onMounted(() => {
      // setCurrentPageTitle("Detail Berita");
      setCurrentPageBreadcrumbs("Detail Berita", ["Berita"]);
    });

    return {
      router,
      route,
      berita,
      fetchStreamDetail,
      goToRoute,
      formatDate,
      id,
    };
  },
  methods: {
    imgError(event) {
      event.target.src = "/img/imgerror.51c45791.png";
    },
    linebreak: function (str) {
      if (str) {
        return str.replace(/\n/g, "<br />");
      }
      return "wow";
    },
    goTo: function (url) {
      window.open(url, "_blank");
    },
    getYoutubeId(url) {
      return url.substring(32, 100);
    },
  },
});

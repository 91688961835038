import ApiService from "@/core/services/ApiService";
import axios from "axios";
import JwtService from "@/core/services/JwtService";
interface IStreams {
  title: string;
  source: string;
  account: string;
  date: string;
  author: string;
  _id: string;
  file_source: Array<string>;
}

interface ModelOcr {
  id: number;
  value: string;
  type: string;
  config: Map<string, number>;
}

const streams: Array<IStreams> = [];

export { IStreams, ModelOcr };

export default streams;

export const getStreams = async (payload) => {
  const response = await ApiService.post("/streams", { data: payload });
  return response.data;
};

export const updateStreams = async (payload, id) => {
  const response = await ApiService.post("/streams/update/" + id, {
    data: payload,
  });
  return response;
};
export const updateStreamsSosmed = async (payload, id) => {
  const response = await ApiService.post("/streams/update-socmed/" + id, {
    data: payload,
  });
  return response;
};

export const getStreamsDetail = async (id) => {
  const response = await ApiService.get(`/streams/detail/${id}`);
  return response.data;
};

export const deleteStream = async (id) => {
  const response = await ApiService.post(`/streams/delete/${id}`, { data: {} });
  return response.data;
};
export const deleteManyStream = async (id: Array<any>) => {
  const response = await ApiService.post(`/streams/bulk-delete`, {
    data: {
      ids: id,
    },
  });
  return response;
};

export const ocrGambar = async (formdata) => {
  let data = {};
  await axios
    .post(`${process.env.VUE_APP_API_OCR_URL}/ocr`, formdata, {
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`,
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    })
    .then((res) => {
      if (res.status == 200) {
        data = { result: res.data, message: "Berhasil" };
      } else {
        data = { result: null, message: "Terjadi Kesalahan" };
      }
    })
    .catch((err) => {
      console.log(err);
      data = { result: null, message: "Terjadi Kesalahan" };
    });

  return data;
};
export const magictool = async (formdata) => {
  let data = {};
  await axios
    .post(`${process.env.VUE_APP_API_OCR_URL}/ocr/deteksi_area`, formdata, {
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`,
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    })
    .then((res) => {
      if (res.status == 200) {
        data = { result: res.data, message: "Berhasil" };
      } else {
        data = { result: null, message: "Terjadi Kesalahan" };
      }
    })
    .catch((err) => {
      console.log(err);
      data = { result: null, message: "Terjadi Kesalahan" };
    });

  return data;
};
export const uploadGambar = async (formdata) => {
  let data = {};
  await axios
    .post(`${process.env.VUE_APP_API_URL}/streams/uploadfile`, formdata, {
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`,
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    })
    .then((res) => {
      if (res.status == 200) {
        data = { result: res.data, message: "Berhasil" };
      } else {
        data = { result: null, message: "Terjadi Kesalahan" };
      }
    })
    .catch((err) => {
      console.log(err);
      data = { result: null, message: "Terjadi Kesalahan" };
    });

  return data;
};
export const getScraperSosmed = async (url, formdata) => {
  let data = {};
  await axios
    .get("https://scraper.kurasi.media" + url, { params: formdata })
    .then((res) => {
      if (res.status == 200) {
        data = { result: res.data, message: "Berhasil" };
      } else {
        data = { result: null, message: "Terjadi Kesalahan", error: true };
      }
    })
    .catch((err) => {
      data = { result: null, message: "Terjadi Kesalahan", error: true };
    });

  return data;
};
export const checkNews = async (url) => {
  let data = {};
  await axios
    .get("https://scsv.onlinemonitoring.id/stream/cek-link", { params: url })
    .then((res) => {
      if (res.status == 200) {
        data = { result: res.data, message: "Berhasil" };
      } else {
        data = { result: null, message: "Terjadi Kesalahan", error: true };
      }
    })
    .catch(() => {
      data = { result: null, message: "Terjadi Kesalahan", error: true };
    });
  return data;
};
export const changeScraperNews = async (id, params) => {
  let data = {};
  await axios
    .put(`https://scsv.onlinemonitoring.id/stream/edit/${id}`, {
      data: params,
    })
    .then((res) => {
      if (res.status == 200) {
        data = { result: res.data, message: "Berhasil" };
      } else if (res.status == 204) {
        data = { result: null, message: "Nochanges" };
      } else {
        data = { result: null, message: "Terjadi Kesalahan", error: true };
      }
    })
    .catch(() => {
      data = { result: null, message: "Terjadi Kesalahan", error: true };
    });
  return data;
};
